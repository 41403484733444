body {
  margin-bottom: 20px;
}

h1 {
  font-weight: 100;
  color: #0e101c;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(79, 98, 148);
}

 h2 {
  line-height: 2;
}

p {
  color: #bf1650;
}

#copyright {
  text-align: center;
}

.errorMessages {
  background-color: pink;
  padding: 20px 20px 20px 20px;
  border-radius: 3px;
}